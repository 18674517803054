import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/Shared/layoutWhite';

const MainStyled = styled.main`
  margin: 10vw;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

const SectionStyled = styled.section``;

const MessageDiv = styled.div``;

const MainHeading = styled.h1`
  font-size: 3rem;

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  position: relative;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 1px solid green;
  background-color: transparent;
  color: #663399;
`;

const SecondaryHeading = styled.h2`
  font-size: 1.75rem;
  text-rendering: optimizelegibility;
  letter-spacing: -0.015em;
  line-height: 1.125;
  font-weight: normal;
  margin-bottom: 1.5rem;
  max-width: 400px;

  & span {
    margin-left: 5px;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <MainStyled>
        <SectionStyled>
          <MessageDiv>
            <MainHeading>Page not found</MainHeading>
            <SecondaryHeading>
              We couldn't find what you were looking for.
              <span role='img' aria-label='Sad face emoji'>
                😔
              </span>
            </SecondaryHeading>
            <GatsbyLinkStyled to='/'>alonso.wtf Homepage</GatsbyLinkStyled>
          </MessageDiv>
        </SectionStyled>
      </MainStyled>
    </Layout>
  );
};

export default NotFoundPage;
